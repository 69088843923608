/* You can add global styles to this file, and also import other style files */
@import 'bootstrap';
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

html,
body {
  height: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.centralizador {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0.5625rem;
}
